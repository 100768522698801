export const LIST_PROJECT = 'LIST_PROJECT'
export const LIST_PROJECTS = 'LIST_PROJECTS'
export const ADD_PROJECT = 'ADD_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const LIST_PROJECT_FILES = 'LIST_PROJECT_FILES'
export const ADD_PROJECT_FILES = 'ADD_PROJECT_FILES'
export const ADD_PUBLICDATA_PROJECT_FILES = 'ADD_PUBLICDATA_PROJECT_FILES'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_FILES = 'GET_FILES'
export const GET_USER_FILES = 'GET_USER_FILES'
export const CURRENT_USER = 'CURRENT_USER'
export const LIST_COLLABORATORS_FILES = 'LIST_COLLABORATORS_FILES'
export const GET_REPORTS = 'GET_REPORTS'
export const ADD_REPORT = 'ADD_REPORT'
export const SEGREGATE_READS = 'SEGREGATE_READS'

/* EXPERIMENTAL DESIGN */

export const GET_EXPIRMENT = 'GET_EXPIRMENT'
export const ADD_EXPIRMENT = 'ADD_EXPIRMENT'
export const ADD_VARIABLE = 'ADD_VARIABLE'
export const REMOVE_VARIABLE = 'REMOVE_VARIABLE'
export const ADD_DIMENSION = 'ADD_DIMENSION'
export const UPDATE_DIMENSION = 'UPDATE_DIMENSION'
export const REMOVE_DIMENSION = 'REMOVE_DIMENSION'


// Internal Admin

export const GET_ADMIN_USERS_LOADING = 'GET_ADMIN_USERS_LOADING'
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS'
export const GET_ADMIN_USER = 'GET_ADMIN_USER'
export const DELETE_ADMIN_USERS = 'DELETE_ADMIN_USERS'
export const ADD_ADMIN_USERS = 'ADD_ADMIN_USERS'
export const UPDATE_IS_DISABLED = 'UPDATE_IS_DISABLED'
export const UPDATE_ADMIN_USER = 'UPDATE_ADMIN_USER'


// User Admin

export const GET_DIVISION = 'GET_DIVISION'
export const GET_USERADMIN_USERS = 'GET_USERADMIN_USERS'
export const GET_USERADMIN_USERS_LOADING = 'GET_USERADMIN_USERS_LOADING'
export const GET_USER_ORGID = 'GET_USER_ORGID'
export const GET_USER_SELECTED_TAB = "GET_USER_SELECTED_TAB"
export const GET_SINGLE_USERADMIN_USERS = 'GET_SINGLE_USERADMIN_USERS'
export const UPDATE_SINGLE_USERADMIN_USERS = 'UPDATE_SINGLE_USERADMIN_USERS'
export const ADD_USERADMIN_USERS = 'ADD_USERADMIN_USERS'
export const DELETE_USERADMIN_USERS = 'DELETE_USERADMIN_USERS'
export const DELETE_USERADMIN_USERS_REMOVE_FILES = ' DELETE_USERADMIN_USERS_REMOVE_FILES'
export const DELETE_USERADMIN_USERS_TRANSFER_FILES = 'DELETE_USERADMIN_USERS_TRANSFER_FILES'
export const ADD_DIVISION = 'ADD_DIVISION'
export const DELETE_DIVISION = 'DELETE_DIVISION'
export const GET_SINGLE_DIVISION = 'GET_SINGLE_DIVISION'
export const UPDATE_DIVISION = 'UPDATE_DIVISION'
export const UPDATE_USERADMIN_IS_DISABLED = 'UPDATE_USERADMIN_IS_DISABLED'
export const RESET_MFA = 'RESET_MFA'

// Division

export const GET_DIVISION_USER_ALL = 'GET_DIVISION_USER_ALL'
export const DELETE_DIVISION_USER = 'DELETE_DIVISION_USER'
export const UPDATE_DIVISION_IS_DISABLED = 'UPDATE_DIVISION_IS_DISABLED'
export const SET_USER_SELECTED_DIVISION = 'SET_USER_SELECTED_DIVISION'
export const GET_DIVISIONS_LOADING = "GET_DIVISIONS_LOADING"

// Billing Account

export const GET_BILLING_ACCOUNT = 'GET_BILLING_ACCOUNT'
export const ADD_BILLING_ACCOUNT = 'ADD_BILLING,ACCOUNT'
export const DELETE_BILLING_ACCOUNT = 'DELETE_BILLING_ACCOUNT'
export const GET_SINGLE_BILLING_ACCOUNT = 'GET_SINGLE_BILLING_ACCOUNT'
export const UPDATE_SINGLE_BILLING_ACCOUNT = 'UPDATE_SINGLE_BILLING_ACCOUNT'
export const GET_BILLING_ACCOUNT_DIVISION = 'GET_BILLING_ACCOUNT_DIVISION'
export const ADD_BILLING_ACCOUNT_DIVISION = 'ADD_BILLING_ACCOUNT_DIVISION'
export const DELETE_BILLING_ACCOUNT_DIVISION = 'DELETE_BILLING_ACCOUNT_DIVISION'
export const SET_USER_SELECTED_BILLING = 'SET_USER_SELECTED_BILLING'
export const BILLING_CLERE = 'BILLING_CLERE'
export const DIVISION_CLERE = 'DIVISION_CLERE'
export const GET_BILL_AMOUNT = 'GET_BILL_AMOUNT' 
export const RESET_BILLING_DATA = 'RESET_BILLING_DATA'
export const GET_BILL_DETAIL = 'GET_BILL_DETAIL'
export const GET_BILLING_LOADING = "GET_BILLING_LOADING"
export const GET_BILLING_ACCOUNT_LOADING = "GET_BILLING_ACCOUNT_LOADING"