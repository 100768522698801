import React, { useState, useEffect } from "react";
import axios from "axios";

const AutoGenReport = ({ pipeline_info }) => {
  const [iframeUrl, setIframeUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const bucketName = `gs://skygenic-user-${pipeline_info.created_user}-standard-1`;
        const fileName = `${pipeline_info.nfdetails.outdir}/multiqc/star_salmon/multiqc_report.html`.split(
          bucketName + "/"
        )[1];

        const response = await axios.post(
          "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/download",
          {
            bucketName: bucketName,
            fileName: fileName,
          }
        );

        // Fetch the HTML content as a Blob
        const htmlResponse = await axios.get(response.data, {
          responseType: "blob",
        });

        // Create a Blob URL from the response
        const blob = new Blob([htmlResponse.data], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        setIframeUrl(url);
      } catch (err) {
        console.error(err);
        setError("Failed to load the report.");
      } finally {
        setLoading(false);
      }
    };

    fetchContent();

    // Cleanup the Blob URL when the component unmounts or iframeUrl changes
    return () => {
      if (iframeUrl) {
        URL.revokeObjectURL(iframeUrl);
      }
    };
  }, [pipeline_info]);

  if (loading) {
    return <div className="div-block-26" style={{ height: "1000px" }}>Loading...</div>;
  }

  if (error) {
    return <div className="div-block-26" style={{ height: "1000px" }}>{error}</div>;
  }

  return (
    <div className="div-block-26" style={{ height: "1000px" }}>
      {iframeUrl ? (
        <iframe
          src={iframeUrl}
          width="100%"
          height="100%"
          style={{ border: "none" }}
          title="Report"
        >
          <p>
            Your browser does not support iframes. You can view the report{" "}
            <a href={iframeUrl}>here</a>.
          </p>
        </iframe>
      ) : (
        <div>No content available.</div>
      )}
    </div>
  );
};

export default AutoGenReport;
