import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { segregateReads } from "../../../actions/projects";
import { useDispatch } from "react-redux";
import RightGuideSlideout from "../RightGuideSlideout";

const InputFiles = ({
  plf,
  inputFileOut,
  setInputFileOut,
  selectedRootWorkflow,
  workflow
}) => {
  const [showInputsModal, setShowInputsModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [warnings, setWarnings] = useState(null);
  const [nFiles, setNFiles] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [paired, setPaired] = useState(true);
  const dispatch = useDispatch();

  // const handleInputSelect = () => {

  //   setNFiles(x.selectedCount * 2);
  // };

  const handleCloseInputsModal = () => {
    let file_indexs = Object.keys(rowSelection)
      .filter((x) => !isNaN(x))
      .map(Number);

    let s_files = filteredFiles.filter((_, i) => file_indexs.includes(i));
    console.log(s_files);

    setSelectedFiles(s_files);
    setInputFileOut(s_files);
    setShowInputsModal(false);
  };

  function round(num, decimalPlaces = 0) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
  }

  const inputColumns = useMemo(() => [
    {
      header: "Name",
      accessorKey: "file_name",
      sortable: true,
      format: ({ file_name, file_color }) => (
        <span
          className="file"
          style={{
            "--color": `#${file_color}`,
            "--hover-color": file_color === "ffffff" ? "" : `#${file_color}`,
            "--margin": file_color === "ffffff" ? "10px" : `5px`,
          }}
        >
          {file_name}
        </span>
      ),
    },
    {
      header: "Tags",
      accessorKey: "tags",
      Cell: ({ renderedCellValue }) => (
        <span style={{ margin: "5px" }}>
          {renderedCellValue.map((tag, index) => (
            <span className="tag" key={index}>
              {tag}
            </span>
          ))}
        </span>
      ),
      wrap: true,
    },
    // {
    //   name: "Exp Dsgn Tags",
    //   accessorKey: "tags",
    //   format: ({ tags }) => (
    //     <span style={{ margin: "5px" }}>
    //       {tags.map((tag, index) => (
    //         <span className="tag" key={index}>
    //           {tag}
    //         </span>
    //       ))}
    //     </span>
    //   ),
    //   wrap: true,
    // },

    {
      header: "Created",
      accessorKey: "date_created",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        <div>{moment.unix(renderedCellValue).format("MM/DD/YYYY")}</div>
      ),
    },
    {
      header: "Type",
      accessorKey: "file_type",
      sortable: true,
    },
    {
      header: "Size",
      accessorKey: "file_size",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        <div>
          {renderedCellValue === null
            ? ""
            : renderedCellValue < 1000
            ? `${renderedCellValue} Bytes`
            : renderedCellValue < 1000000
            ? `${round(renderedCellValue / 1000, 2)} KB`
            : renderedCellValue < 1000000000
            ? `${round(renderedCellValue / 1000000, 2)} MB`
            : `${round(renderedCellValue / 1000000000, 2)} GB`}
        </div>
      ),
    },

    {
      header: "Folder",
      // accessorKey: "folder",
      id: "folder",
      Cell: ({ renderedCellValue, row }) => {
        let folders = row.original.file_path.split("/");
        folders.pop();
        folders = folders.filter((folder) => folder !== "");
        console.log(folders, "folders");

        return (
          <span style={{ margin: "5px" }}>
            {folders.map((folder, index) => (
              <span className="tag" key={index}>
                {folder}
              </span>
            ))}
          </span>
        );
      },
    },
    {
      header: "Paired",
      accessorKey: "paired",
      sortable: true,
      hide: true,
    },
  ]);

  // useEffect(() => {
  //   setInputFileOut([plf]);
  // }, [plf]);

  useEffect(() => {
    const fetchSegregateReads = async () => {
      if (!plf) return;

      let directoryMap = {};
      plf.filter(file => file.file_name.includes('.fq') || file.file_name.includes('.fastq')).forEach((file) => {
        const directory = file.imaginary_file_directory;
        if (!directoryMap[directory]) {
          directoryMap[directory] = [];
        }
        if (directoryMap[directory].length < 4) {
          directoryMap[directory].push(file.file_name);
        }
      });

      console.log(directoryMap, "directoryMap");

      try {
        const result = await dispatch(segregateReads(directoryMap));
        const regex_2_segregate_samples_based_on_reads = result;

        let newPlf = plf.map((file) => {
          const directory = file.imaginary_file_directory;
          const regexPatterns = regex_2_segregate_samples_based_on_reads[directory];
          console.log(regexPatterns, "regexPatterns");
          // Check if regexPatterns is defined before parsing
          if (regexPatterns) {
            const { read1, read2, sampleName } = JSON.parse(regexPatterns);
            console.log(read1, read2, "read1, read2");
            const matchRead1 = file.file_name.match(new RegExp(read1));
            console.log(matchRead1, "matchRead1");
            const matchRead2 = file.file_name.match(new RegExp(read2));
            console.log(matchRead2, "matchRead2");

            const sampleNameMatch = file.file_name.match(new RegExp(sampleName));
            console.log(sampleNameMatch, "sampleNameMatch");

            if (matchRead1) {
              file.paired = sampleNameMatch[0] // Set paired name for read 1
              file.read_num = '1'; // Indicate this is read 1
            } else if (matchRead2) {
              file.paired = sampleNameMatch[0] // Set paired name for read 2
              file.read_num = '2'; // Indicate this is read 2
            } else {
              file.paired = sampleName // Default to file name
              file.read_num = null; // No read number
            }
            console.log(file.file_name, file.paired, file.read_num, "file");
          } else {
            console.warn(`No regex patterns found for directory: ${directory}`);
            file.paired = file.file_name.split(".")[0]; // Default to file name
            file.read_num = null; // No read number

          }

          return file;
        });


        newPlf = newPlf.filter(file => file.file_name.includes('.fastq') || file.file_name.includes('.fq'))
        setFilteredFiles(newPlf);
      } catch (error) {
        console.error("Error segregating reads:", error);
      }
    };

    fetchSegregateReads();
  }, [plf]);

  useEffect(() => {
    setWarnings(false);
    console.log(selectedFiles, "selectedFiles");
    // selectedFiles.map((ff) => {
    //     console.log(ff.read_num, ff.paired, !selectedFiles.find((f) => f.paired === ff.paired && f.read_num === '1'), 'uuuuuu')
    //     if(ff.read_num === '2' && !selectedFiles.find((f) => f.paired === ff.paired && f.read_num === '1')){
    //     // if(ff.read_num == '2'){
    //       setWarnings(`Warning: ${ff.paired} is missing a read 1 file`)
    //   }
    // })
    function checkFastqFiles(arr) {
      const pairedSamples = {};
      const unpairedSamples = {};

      for (const obj of arr) {
        const name = obj.file_name;
        const paired = obj.paired;
        const isRead1 = obj.read_num === '1';
        const isRead2 = obj.read_num === '2';

        if (isRead1) {
          if (!(paired in pairedSamples)) {
            pairedSamples[paired] = {};
          }
          pairedSamples[paired]["read1"] = true;
        } else if (isRead2) {
          if (!(paired in pairedSamples)) {
            pairedSamples[paired] = {};
          }
          pairedSamples[paired]["read2"] = true;
        } else {
          unpairedSamples[name] = true;
        }
      }

      let warnings = "";

      for (const paired of Object.keys(pairedSamples)) {
        if (!pairedSamples[paired]["read1"]) {
          warnings += `${paired} is missing read 1 file\n`;
        }
        if (pairedSamples[paired]["read2"]) {
          for (const otherPaired of Object.keys(pairedSamples)) {
            if (
              paired !== otherPaired &&
              !pairedSamples[otherPaired]["read2"]
            ) {
              warnings += `${paired} has read 2 file but ${otherPaired} is missing read 2 file\n`;
            }
          }
        }
      }

      if (Object.keys(unpairedSamples).length > 0) {
        warnings += `unpaired samples detected: ${Object.keys(
          unpairedSamples
        ).join(", ")}\n`;
      }

      return warnings;
    }

    setWarnings(checkFastqFiles(selectedFiles));
  }, [selectedFiles]);

  return (
    <>
      <div className="text-block-50">
        {selectedRootWorkflow && selectedRootWorkflow.steps[1].description}
      </div>
      {warnings && (
        <div className="div-warning">
          Warning: Missing read files
          <div class="div-block-99" onClick={() => setShowWarningModal(true)}>
            Learn More
          </div>
        </div>
      )}
      <div
        // className="text-block-42"
        className="btn-1 type-2"
        style={{
          width: "200px",
          padding: "0px 20px",
          marginBottom: "30px",
          marginTop: "15px",
          minHeight: "45px",
        }}
        onClick={() => setShowInputsModal(true)}
      >
        <div className="btn-1-text">Add Input Files</div>
      </div>
      <div
        style={{
          width: "60vw",
          position: "relative",
        }}
      >
        <RightGuideSlideout guideContent={workflow.steps['input']['settings']['guide']} />

        <MaterialReactTable
          columns={inputColumns}
          data={selectedFiles ? selectedFiles : []}
          enableGrouping={paired}
          enableStickyHeader
          initialState={{
            density: "compact",
            expanded: false, //expand all groups by default
            grouping: ["paired"], //an array of columns to group by by default (can be multiple)
            pagination: { pageIndex: 0, pageSize: 20 },
            sorting: [{ id: "paired", desc: false }], //sort by state by default
          }}
          enableTopToolbar={false}
          // muiToolbarAlertBannerChipProps={{ color: "primary" }}
          // muiTableContainerProps={{ sx: { maxHeight: 700, width: '100%' } }}
          // onRowSelectionChange={setRowSelection}
        />
      </div>

      <Modal
        dialogClassName="modal-90w"
        show={showInputsModal}
        onHide={handleCloseInputsModal}
        size="lg"
        centered
        style={{
          overflowY: "auto",
          maxHeight: "98vh",
        }}
      >
        <>
          <div
            className="text-block-2"
            style={{
              padding: "20px 40px",
              alignSelf: "center",
            }}
          >
            Input Files
          </div>

          {/* <div
            className="div-block-53"
            style={{
              height: "-10px",
            }}
          >
            <div
              className="div-block-58"
              style={{
                justifyContent: "space-evenly",
              }}
            ></div>
          </div> */}

          <div
            style={{
              width: "100%",
              padding: "10px 50px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <input
                placeholder="Filter"
                style={{
                  width: "30%",
                  padding: "10px",
                  margin: "10px 0px",
                  border: "none",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  outline: "none",
                  backgroundColor: "#d0d2db",
                  color: "#000000",
                }}
                defaultValue={globalFilter}
                onChange={(e) => {
                  // filter filteredFiles on innerText
                  // const filtered = plf.filter((x) =>
                  //   x.file_name
                  //     .toLowerCase()
                  //     .includes(e.target.value.toLowerCase())
                  // );
                  // setFilteredFiles(filtered);
                  setGlobalFilter(e.target.value);
                }}
              ></input>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={paired}
                      onChange={() => setPaired(!paired)}
                    />
                  }
                  label="Paired"
                />
              </FormGroup>
            </div>
            <MaterialReactTable
              columns={inputColumns}
              data={filteredFiles ? filteredFiles : []}
              // data={filteredFiles ? filteredFiles.filter(file => file.file_name.includes('.fastq') || file.file_name.includes('.fq')) : []}
              enableGrouping
              enableStickyHeader
              enableRowSelection
              // enableStickyFooter
              initialState={{
                density: "compact",
                expanded: false, //expand all groups by default
                grouping: paired ? ["paired"] : [], //an array of columns to group by by default (can be multiple)
                pagination: { pageIndex: 0, pageSize: 20 },
                sorting: [{ id: "paired", desc: false }], //sort by state by default
              }}
              state={{
                globalFilter,
                rowSelection,
                grouping: paired ? ["paired"] : [],
              }}
              onGlobalFilterChange={setGlobalFilter}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              muiTableContainerProps={{ sx: { maxHeight: 700 } }}
              onRowSelectionChange={setRowSelection}
              muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: { cursor: "pointer" },
              })}
            />
          </div>
        </>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <div></div>

          <Button
            variant="secondary"
            style={{
              marginRight: "20px",
            }}
            onClick={handleCloseInputsModal}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        keyboard={true}
        centered
        size="sm"
      >
        <div
          className="div-block-100"
          style={{
            width: "100%",
          }}
        >
          <div className="text-block-55">Warning: Missing paired files. </div>
          <div className="div-block-101">
            {warnings &&
              warnings.split("\n").map((warning) => {
                return <div className="text-block-56">{warning}</div>;
              })}
          </div>
        </div>

        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button
            variant="secondary"
            style={{
              marginRight: "20px",
            }}
            onClick={() => setShowWarningModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default InputFiles;
