import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import moment from "moment";
import { MaterialReactTable } from "material-react-table";
import DriveModal from "./driveModal";
import RightGuideSlideout from "../RightGuideSlideout";

const OtherSettings = ({
  plf,
  setOtherSettingsOut,
  workflow,
  selectedStep,
}) => {
  const [otherSettings, setOtherSettings] = useState([]);
  const [showDriveModal, setShowDriveModal] = useState(false);
  const [activeChoosenInput, setActiveChoosenInput] = useState();

  const [handleRefSelect, setHandleRefSelect] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);

  useEffect(() => {
    if (selectedStep[0].includes("others")) {
      let _otherSettings = [];
      console.log(selectedStep);
      workflow?.steps[selectedStep[0]]?.settings.forEach((item) => {
        item.value = item.test_input;
        console.log(item);
        _otherSettings.push(item);
      });
      setOtherSettings(_otherSettings);
      setOtherSettingsOut(_otherSettings);
    }
  }, [workflow, selectedStep]);

  useEffect(() => {
    // Row selection
    console.log(rowSelection);
    try {
      let selectedRow = Object.keys(rowSelection);
      selectedRow = parseInt(selectedRow[0]);

      let x = plf[selectedRow];
      if (!x) return;
      let _otherSettings = otherSettings.map((item) => {
        if (item.name === activeChoosenInput.name) {
          return { ...item, value: x.file_path, file_doc: x };
        }
        return item;
      });
      setOtherSettings(_otherSettings);
      setOtherSettingsOut(_otherSettings);
    } catch (error) {
      console.log(error);
    }
  }, [rowSelection]);

  return (
    <>
      <div
        className="analysis-reference-block"
        style={{
          display: "flex",
        }}
      >
        {/* <div className="text-block-50">{"stepInfo"}</div> */}
        <div className="text-block-50"></div>
        {console.log(selectedStep)}
        {selectedStep[0].includes("others") &&
          workflow?.steps[selectedStep[0]]?.sections.map((section) => {
            return (
              <>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "4px",
                }}
              >
                {section}
              </div>
              <div className="div-block-95-copy">
                {otherSettings.map((otherSetting, index) => {
                  if (otherSetting.section === section) {
                    let _options = [];
                    if (Array.isArray(otherSetting.options)) {
                      _options = otherSetting.options;
                    } else if (typeof otherSetting.options === "object") {
                      console.log("line 79", otherSetting, otherSettings);
                      let _value = otherSettings.find(
                        (item) => item.name === otherSetting.options.parent
                      ).value;
                      otherSetting.options.options.forEach((item) => {
                        console.log(item);
                        if (item["parent-value"] === _value) {
                          _options = item.options;
                        }
                      });
                      console.log(_options);
                    }
                    return (
                      <div
                        className="div-block-96"
                        style={{
                          display: "flex",
                        }}
                      >
                        {otherSetting.guide && (
                          typeof otherSetting.guide === 'object' ? (
                            (() => {
                              let _value = otherSettings.find(
                                (item) => item.name === otherSetting.guide.parent
                              ).value;
                              let guideContent;
                              otherSetting.guide.options.forEach(item => {
                                if (item["parent-value"] === _value) {
                                  guideContent = item.guide;
                                }
                              });
                              return (
                                <RightGuideSlideout
                                  guideContent={guideContent}
                                />
                              );
                            })()
                          ) : (
                            <RightGuideSlideout
                              guideContent={otherSetting.guide}
                            />
                          )
                        )}
                          
                        <div
                          className="text-block-52"
                          style={{
                            display: "block",
                          }}
                        >
                          {otherSetting.name.charAt(0).toUpperCase() +
                            otherSetting.name.slice(1)}{" "}
                          - <br />
                          {(() => {
                            try {
                              return (
                                <b>{otherSetting?.value.split("/").pop()}</b>
                              );
                            } catch (error) {
                              return <b>{otherSetting?.value}</b>;
                            }
                          })()}
                          {/* <b>{otherSetting?.value.split("/").pop()}</b> */}
                        </div>
                        {handleRefSelect.length > 0 ? (
                          <div
                            className="reference-dropdown"
                            onClick={() => setShowDriveModal(true)}
                          >
                            {handleRefSelect?.selectedRows[0]?.file_name}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="div-block-98">
                          <div
                            style={{
                              display:
                                otherSetting.interface_type === "dropdown"
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <Select
                              value={{
                                value: otherSetting.value
                                  ? otherSetting.value
                                  : _options[0]?.value,
                                label: otherSetting.value
                                  ? otherSetting.value
                                  : _options[0]?.label,
                              }}
                              onChange={(selectedOption) => {
                                const updatedOtherSettings = [...otherSettings];
                                updatedOtherSettings[index].value =
                                  selectedOption.value;
                                updatedOtherSettings[index].file_doc = null;
                                setOtherSettings(updatedOtherSettings);
                                setOtherSettingsOut(updatedOtherSettings);
                              }}
                              options={_options}
                              className={"reference-dropdown"}
                              styles={{
                                width: "100%",
                              }}
                              isDisabled={
                                handleRefSelect.length === 0 ? false : true
                              }
                              placeholder="Reference Files"
                              menuPosition="fixed"
                              menuPlacement="auto"
                            />
                          </div>
                          <div
                            // className="text-block-53"
                            className="btn-1 type-2"
                            style={{
                              marginLeft: "14px",
                              display: otherSetting.interface_type.includes(
                                "drive_file_selector"
                              )
                                ? "flex"
                                : "none",
                            }}
                            onClick={() => {
                              setShowDriveModal(true);
                              setActiveChoosenInput(otherSetting);
                              // setGlobalFilter(otherSetting.extension[0]);
                            }}
                          >
                            <div
                              className="btn-1-text"
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Choose From <br />
                              Storage
                            </div>
                          </div>
                          <div
                            // className="text-block-53"
                            className="btn-1 type-2"
                            style={{
                              marginLeft: "14px",
                              display: otherSetting.interface_type.includes(
                                "external_link"
                              )
                                ? "flex"
                                : "none",
                            }}
                            onClick={() => {
                              let _link;
                              let _value = otherSettings.find(
                                (item) =>
                                  item.name === otherSetting.options.parent
                              ).value;
                              if (!_value) {
                                _value =
                                  otherSetting.options.options[0][
                                    "parent-value"
                                  ];
                              }
                              otherSetting.options.options.forEach((item) => {
                                if (item["parent-value"] === _value) {
                                  _link = item.link;
                                }
                              });
                              window.open(_link, "_blank");
                            }}
                          >
                            <div
                              className="btn-1-text"
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Open Link
                            </div>
                          </div>
                          <div
                            // className="text-block-53"
                            // className="btn-1 type-2"
                            style={{
                              marginLeft: "14px",
                              display: otherSetting.interface_type.includes(
                                "number-input"
                              )
                                ? "flex"
                                : "none",
                            }}
                          >
                          <input
                            type="number"
                            // className="reference-dropdown"
                            defaultValue={otherSetting.test_input}
                            style={{
                              width: "100px",
                              padding: "5px",
                              fontSize: "14px",
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Handle the change event as needed
                              console.log("Number input value:", value);
                              const updatedOtherSettings = [...otherSettings];
                              updatedOtherSettings[index].value = value;
                              setOtherSettings(updatedOtherSettings);
                              setOtherSettingsOut(updatedOtherSettings);
                            }}
                          />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          );
        })}
      </div>
      <DriveModal
        show={showDriveModal}
        handleClose={() => setShowDriveModal(false)}
        data={plf}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </>
  );
};

export default OtherSettings;
