import { v4 as uuidv4 } from "uuid";
import { analysisCreate, startNFAnalysis } from "../../../actions/projects";
import axios from "axios";
import { toast } from "react-toastify";


const uploadFile = async (file, user_bucket, filePath) => {
  console.log("Upload function", filePath, file);
  let inputformData = new FormData();
  inputformData.append("file", file);
  inputformData.append("bucketName", user_bucket);
  inputformData.append("filePath", filePath);

  var config = {
    method: "post",
    // url: "http://localhost:8080/upload",
    url: "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/upload",
    data: inputformData,
  };

  try {
    const response = await axios(config);
    console.log(response);
    console.log("File uploaded done", user_bucket, filePath, file);
  } catch (error) {
    console.log(error);
  }
};

const startAnalysis = async (
  selectedWorkflow,
  inputFileOut,
  inputFile2Out,
  referenceFileOut,
  expDesignOut,
  comparisonSelectionOut,
  vmSpeed,
  otherSettingsOut,
  dispatch,
  currentUser,
  params,
  selectedPipeline
) => {
  let run_id = uuidv4();

  console.log("selectedWorkflow", selectedWorkflow);
  console.log("inputFileOut", inputFileOut);
  console.log("inputFile2Out", inputFile2Out);
  console.log("referenceFileOut", referenceFileOut);
  console.log("expDesignOut", expDesignOut);
  console.log("comparisonSelectionOut", comparisonSelectionOut);
  console.log("vmSpeed", vmSpeed);
  console.log("otherSettingsOut", otherSettingsOut);


  const generateContrastFile = async (expDesignData, user_bucket, run_id, pipeline) => { 
    const columns = ['id', 'variable', 'reference', 'target'];
    // const metadataColumns = Object.keys(expDesignData[0]).filter(col => col !== 'sample_row');

    //  Get all unique values for a given column
    const uniqueValues = [...new Set(expDesignData.map(exp => exp['condition']))];

    const row = [`condition_${uniqueValues[0]}_${uniqueValues[1]}`, 'condition', uniqueValues[0], uniqueValues[1]]
    // const csvContent = `id,variable,reference,target\ncondition_control_treated,condition,control,treated\n`;
    const csvContent = columns.join(',') + '\n' + row.join(',') + '\n';
    console.log("ContrastcsvContent", csvContent);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const file = new File([blob], 'contrast_file.csv', { type: 'text/csv' });

    const filePath = `analysis-uploads/${params.id}/${run_id}/${pipeline.name.split("/").pop()}`;
    await uploadFile(file, user_bucket, filePath);

    return `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/${pipeline.name.split("/").pop()}/contrast_file.csv`;
  }

  const getExpDesignData = async (expDesignOut) => {
    let expDesignData;

    // Check if expDesignOut is a GCS path or TSV content
    if (expDesignOut.startsWith('gs://')) {
      // Fetch the TSV content from GCS
      try {
        const response = await axios.post(
          "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/download",
          {
            bucketName: expDesignOut.split('/')[2],
            fileName: `uploads/${expDesignOut.split('/').slice(4).join('/')}`,
          }
        );
        const fileContent = await axios.get(response.data);
        expDesignData = tsv2arr(fileContent.data);
      } catch (error) {
        console.error("Error fetching TSV file:", error);
        throw error;
      }
    } else {
      // Parse the TSV content directly
      expDesignData = tsv2arr(expDesignOut);
    }

    return expDesignData;
  }
  //   // Get all columns except 'sample_row'
  //   const metadataColumns = Object.keys(expDesignData[0]).filter(col => col !== 'sample_row');

  //   // Create CSV header with dynamic columns
  //   const headerRow = ['sample', 'fastq_1', 'fastq_2', ...metadataColumns];
  //   let csvContent = headerRow.join(',') + '\n';

  //   // Process each row in expDesignData
  //   expDesignData.forEach(row => {
  //     const sample = row.sample_row;

  //     // Find corresponding fastq files from inputFileOut
  //     const fastq1 = inputFileOut.find(file => file.paired.includes(sample) && file.read_num === '1');
  //     const fastq2 = inputFileOut.find(file => file.paired.includes(sample) && file.read_num === '2');

  //     // Create fastq paths if files exist
  //     const fastq1Path = fastq1 
  //       ? `gs://${fastq1.bucket_id}/uploads/${fastq1.imaginary_file_path}`
  //       : '';
  //     const fastq2Path = fastq2
  //       ? `gs://${fastq2.bucket_id}/uploads/${fastq2.imaginary_file_path}`
  //       : '';

  //     // Get metadata values for this row
  //     const metadataValues = metadataColumns.map(col => row[col]);

  //     // Combine all values and add row to CSV
  //     const rowValues = [sample, fastq1Path, fastq2Path, ...metadataValues];
  //     csvContent += rowValues.join(',') + '\n';
  //   });
  //   console.log("csvContent", csvContent);

  //   // Create and upload the file
  //   // const blob = new Blob([csvContent], { type: 'text/csv' });
  //   // const file = new File([blob], 'diff_abund_input.csv', { type: 'text/csv' });

  //   // const filePath = `analysis-uploads/${params.id}/${run_id}`;
  //   // await uploadFile(file, user_bucket, filePath);

  //   return `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/diff_abund_input.csv`;
  // };
  
  // Helper function to convert TSV to array of objects
  const tsv2arr = (tsv) => {
    const [headers, ...rows] = tsv
      .trim()
      .split("\n")
      .map((r) => r.split("\t"));
    return rows.reduce(
      (a, r) => [
        ...a,
        Object.assign(
          ...r.map((x, i, _, c = x ? x.trim() : "") => ({
            [headers[i].trim()]: isNaN(c) ? c : Number(c),
          }))
        ),
      ],
      []
    );
  };

      
      

  try {
    let _params = [];
    const user_bucket = `skygenic-user-${currentUser.id}-standard-1`;
    let expDesignData = await getExpDesignData(expDesignOut);

    // Loop over workflow steps, ignore if step is "summary" or "others"
    let steps = Object.keys(selectedWorkflow.steps);
    steps = steps.filter((step) => step !== "summary");

    const pipelines = [];
    selectedWorkflow["nf-run-steps"].pipelines.forEach(async pipeline => {
      let pipeline_params = [];
      let input_csv = "";

      pipeline.parameters.forEach(async param => {
        if (param.name === "input") {
          let columns = param.columns;

          // Add CSV header
          input_csv +=
            columns
              .map((column) =>
                column.includes(":") ? column.split(":")[0] : column.includes("---ExperimentDesign") ? column.split("---ExperimentDesign")[0] : column
              )
              .join(",") + "\n";
          // get all unique sample ids
          let sample_ids = [
            ...new Set(inputFileOut.map((file) => file.paired)),
          ];

          // Check if expDesignOut is a GCS path or TSV content
          
          // each sample id has 1 or 2 fastq files, each sample id gets a row in the csv, sorted by sample id
          sample_ids.sort();
          sample_ids.forEach((sample_id) => {
            const metadataColumns = Object.keys(expDesignData[0]).filter(col => col !== 'sample_row');

            let row = columns
              .map((column) => {
                if (column === "sample") {
                  return sample_id;
                } else if (column.includes("fastq_1")) {
                  let file = inputFileOut.find(
                    (file) =>
                      file.paired === sample_id &&
                      file.read_num === "1"
                  );
                  return file
                    ? `gs://${file.bucket_id}/uploads/${file.imaginary_file_path}`
                    : "";
                } else if (column.includes("fastq_2")) {
                  let file = inputFileOut.find(
                    (file) =>
                      file.paired === sample_id &&
                      file.read_num === "2"
                  );
                  return file
                    ? `gs://${file.bucket_id}/uploads/${file.imaginary_file_path}`
                    : "";
                } else if (column.includes(":")) {
                  return column.split(":")[1];
                } else if (metadataColumns.includes(column)) {
                  console.log("expDesignData", expDesignData);
                  let expDataRow = expDesignData.find(exp => exp.sample_row === sample_id);
                  console.log("expDataRow", expDataRow, column, sample_id);
                  let column_value = expDataRow ? expDataRow[column] : "";
                  return column_value;
                } else {
                  return "";
                }
              })
              .join(",");
            input_csv += row + "\n";
          });

          console.log(input_csv);

          var input_blob = new Blob([input_csv], {
            type: "application/csv",
          });
          var input_file = new File([input_blob], "input.csv", {
            type: "application/csv",
          });
          let filePath = `analysis-uploads/${params.id}/${run_id}/${pipeline.name.split("/").pop()}`;
          let gres = await uploadFile(input_file, user_bucket, filePath);
          console.log(gres);
          

          pipeline_params.push({ input: `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/${pipeline.name.split("/").pop()}/input.csv` });
        } else if (param.type === "reference") {
          let ref_value = referenceFileOut.find(ref => ref.name === param.name).value;
          pipeline_params.push({ [param.name]: ref_value });
        } else if (param.type === "settings") {
          let ref_value = otherSettingsOut.find(ref => ref.name === param.name).value;
          pipeline_params.push({ [param.name]: ref_value });
        } else if (param.type === "func-contrasts") {
          let contrast_file_path = await generateContrastFile(expDesignData, user_bucket, run_id, pipeline);
          pipeline_params.push({ contrasts: contrast_file_path });
        } else {
          pipeline_params.push({ [param.name]: param.value });
        }
      });
      pipelines.push({
        name: pipeline.name,
        params: pipeline_params
      });
    });

    console.log("pipelines", pipelines);
    await new Promise(resolve => setTimeout(resolve, 5000));


    // for (let step of steps) {
    //   console.log("step", step);
    //   let input_csv = "";
    //   if (step === "input") {
    //     if (inputFile2Out) {
    //       let input_file_path = "";
    //       if (inputFile2Out.full_file_path.split("/")[0] === "uploads") {
    //         input_file_path = `gs://${inputFile2Out.bucket_id}/uploads/${inputFile2Out.imaginary_file_path}`;
    //       } else {
    //         console.log(inputFile2Out.full_file_path.split("/")[0])
    //         input_file_path = `gs://${inputFile2Out.bucket_id}/${inputFile2Out.imaginary_file_path}`;
    //       }
          
    //       _params.push({ input: input_file_path });
    //     } else {
    //       let columns = selectedWorkflow.steps[step].settings.columns;

    //       // Add CSV header
    //       input_csv +=
    //         columns
    //           .map((column) =>
    //             column.includes(":") ? column.split(":")[0] : column
    //           )
    //           .join(",") + "\n";
    //       // get all unique sample ids
    //       let sample_ids = [
    //         ...new Set(inputFileOut.map((file) => file.paired.split("_")[0])),
    //       ];
    //       // each sample id has 1 or 2 fastq files, each sample id gets a row in the csv, sorted by sample id
    //       sample_ids.sort();
    //       sample_ids.forEach((sample_id) => {
    //         let row = columns
    //           .map((column) => {
    //             if (column === "sample") {
    //               return sample_id;
    //             } else if (column.includes("fastq_1")) {
    //               let file = inputFileOut.find(
    //                 (file) =>
    //                   file.paired.split("_")[0] === sample_id &&
    //                   file.read_num === "1"
    //               );
    //               return file
    //                 ? `gs://${file.bucket_id}/uploads/${file.imaginary_file_path}`
    //                 : "";
    //             } else if (column.includes("fastq_2")) {
    //               let file = inputFileOut.find(
    //                 (file) =>
    //                   file.paired.split("_")[0] === sample_id &&
    //                   file.read_num === "2"
    //               );
    //               return file
    //                 ? `gs://${file.bucket_id}/uploads/${file.imaginary_file_path}`
    //                 : "";
    //             } else if (column.includes(":")) {
    //               return column.split(":")[1];
    //             } else {
    //               return "";
    //             }
    //           })
    //           .join(",");
    //         input_csv += row + "\n";
    //       });

    //       console.log(input_csv);

    //       // var input_blob = new Blob([input_csv], {
    //       //   type: "application/csv",
    //       // });
    //       // var input_file = new File([input_blob], "input.csv", {
    //       //   type: "application/csv",
    //       // });
    //       // let filePath = `analysis-uploads/${params.id}/${run_id}`;
    //       // let gres = await uploadFile(input_file, user_bucket, filePath);
    //       // console.log(gres);
    //       _params.push({
    //         input: `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/input.csv`,
    //       });
    //     }
    //   } else if (step === "experimentalDesign") {
    //     console.log("expDesignOut", expDesignOut);
    //     console.log("input_csv", input_csv);
    //     let contrast_file_path = generateContrastFile(expDesignOut, user_bucket, run_id);
    //     // let diff_abund_input_file_path = generateDiffAbundInputFile(expDesignOut, user_bucket, run_id, inputFileOut);
    //     // _params.push({
    //     //   contrasts: contrast_file_path,
    //     // });
    //     // _params.push({
    //     //   input: `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/input.csv`,
    //     // });

    //   } else if (step === "reference") {
    //     // Loop over all settings in referenceFileOut
    //     referenceFileOut.forEach((refs) => {
    //       if (refs.file_doc) {
    //         // GCP storage path
    //         let file_path = `gs://${refs.file_doc.bucket_id}/uploads/${refs.file_doc.imaginary_file_path}`;
    //         _params.push({ [refs.name]: file_path });
    //       } else {
    //         _params.push({ [refs.name]: refs.value });
    //       }
    //     });
    //   } else if (step === "others") {
    //     console.log("otherSettingsOut", otherSettingsOut);
    //     otherSettingsOut.forEach((oss) => {
    //       console.log("oss", oss);
    //       if (oss.file_doc) {
    //         console.log("oss.file_doc", oss.file_doc);
    //         let file_path = "";
    //         if (oss.file_doc.full_file_path.split("/")[0] === "uploads") {
    //           file_path = `gs://${oss.file_doc.bucket_id}/uploads/${oss.file_doc.imaginary_file_path}`;
    //         } else {
    //           file_path = `gs://${oss.file_doc.bucket_id}/${oss.file_doc.imaginary_file_path}`;
    //         }
    //         _params.push({ [oss.name]: file_path });
    //       } else {
    //         console.log("oss.value", oss.value);
    //         _params.push({ [oss.name]: oss.value });
    //       }
    //     });
    //   }
    // }

    // console.log(_params);
    
    const nfdetails = {
      nextflowPipeline: `../.nextflow/assets/Skygenic/${
        selectedWorkflow.name.split("/")[1]
      }`,
      // release: "main",
      outdir: `gs://${user_bucket}/analysis-runs/${params.id}/${run_id}/outputs`,
      workdir: `gs://${user_bucket}/gcb/workdir/${params.id}/${run_id}`,
      profile: "gcb",
      run_id: run_id,
      pipelines: pipelines,
    };
    console.log("nfdetails", nfdetails);
    // _params.forEach((param) => {
    //   nfparams[Object.keys(param)[0]] = Object.values(param)[0];
    // });
    // console.log("nfparams", nfparams);

    dispatch(
      startNFAnalysis(params.id, {
        // user_id: "6054074f8c41cc4",
        run_id: run_id,
        nfdetails: nfdetails,
      })
    );

    dispatch(
      analysisCreate(params.id, {
        // user_id: "6054074f8c41cc4",
        run_id: run_id,
        input: JSON.stringify({ input: 1 }),
        status: "Initiating",
        project_id: params.id,
        nfdetails: nfdetails,
      })
    );
    toast.success(
      "Analysis has initiated, Please wait couple minutes for the analysis status",
      { autoClose: 8000 }
    );

    // try {
    //   // const response = await axios(config2);
    //   toast.success("Analysis has successful started running.", {
    //     autoClose: 8000,
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  } catch (error) {
    console.log(error);
    alert("Please fill all the required fields");
  }
};

export default startAnalysis;
