import api from '../../api/projects'
import {ADD_REPORT, ADD_PUBLICDATA_PROJECT_FILES, UPDATE_REPORT, GET_REPORTS, LIST_PROJECT, LIST_PROJECTS, ADD_PROJECT, UPDATE_PROJECT, GET_CATEGORIES, LIST_PROJECT_FILES, ADD_PROJECT_FILES, LIST_COLLABORATORS_FILES, SEGREGATE_READS } from '../types'
import { ToastContainer, toast } from 'react-toastify';


export const listProjects = () => async dispatch => {
  const results = await api.get('/projects')
  dispatch({ type: LIST_PROJECTS, payload: results.data })
}

export const listProject = id => async dispatch => {
  const project = await api.get(`/projects/${id}`)

  dispatch({ type: LIST_PROJECT, payload: project.data })
}

export const addProject = (data) => async dispatch => {
  const project = await api.post(`/projects`, { data })
  dispatch({ type: ADD_PROJECT, payload: project.data })
  return project
}

export const updateProject = (id, data) => async dispatch => {
  const project = await api.patch(`/projects/${id}`, { data })
  // dispatch({ type: UPDATE_PROJECT, payload: project.data })
}

export const deleteProject = (data) => async dispatch => {
  console.log("DDD", data)
  const results = await api.delete(`/projects/${data.projectId}`, { data: data })
  // dispatch({ type: UPDATE_PROJECT, payload: results.data })
}

export const listProjectFiles = id => async dispatch => {
  const project = await api.get(`/projects/${id}/files`)
  dispatch({ type: LIST_PROJECT_FILES, payload: project.data })
}

export const addFilesToProject = (id, data) => async dispatch => {
  const project = await api.patch(`/projects/${id}/files`, { data })
  console.log(data)
  // if error dont dispatch
  if (project.data.error) {
    console.log(project.data.error)
    return
  }
  dispatch({ type: ADD_PROJECT_FILES, payload: project.data })
}

export const addPublicDataFilesToProject = (id, data) => async dispatch => {
  const project = await api.patch(`/projects/${id}/publicDataFiles`, { data })
  console.log(project.data)
  // if error dont dispatch
  if (project.data.error) {
    console.log(project.data.error)
    return
  }
  dispatch({ type: ADD_PROJECT_FILES, payload: project.data })
}

// delete file from project
export const deleteFileFromProject = (id, data) => async dispatch => {
  const project = await api.delete(`/projects/${id}/file`, { data })
  console.log(project.data)
  // dispatch({ type: ADD_PROJECT_FILES, payload: project.data })
}


export const removeFilesFromProject = (id, data) => async dispatch => {
  await api.delete(`/projects/${id}/files`, { data })
}

export const getAllCollaboratorsFiles = (id, data) => async dispatch => {
  const project = await api.get(`/projects/${id}/getAllUserFiles`)
  dispatch({ type: LIST_COLLABORATORS_FILES, payload: project.data })
}


export const getCategories = () => async dispatch => {
  const categories = await api.get(`/categories`)

  dispatch({ type: GET_CATEGORIES, payload: categories.data })
}

export const inviteCollaborators = (id, data) => async dispatch => {
  const project = await api.post(`/projects/${id}/inviteCollaborator`, { data })
  console.log(project.data)
  // dispatch({ type: UPDATE_PROJECT, payload: project.data })
}

export const removeCollaborator = (id, data) => async dispatch => {
  const project = await api.post(`/projects/${id}/removeCollaborator`, { data })
  console.log(project.data)
}

export const segregateReads = (data) => async dispatch => {
  const results = await api.post(`/segregate-reads`, { data })
  if (results.status === 200) {
    console.log(results.data)
    return results.data
  }
}

export const analysisCreate = (id, data) => async dispatch => {
  console.log("data", data)
  const project = await api.post(`/analysis/${id}/create`, data).then((x) => {
    console.log(x)
  })
}

export const startNFAnalysis = (run_id, data) => async dispatch => { 
  const results = await api.post(`/nf-run`, data).then((x) => {
    console.log(x)
  })
}


export const analysisDelete = (run_id) => async dispatch => {
  console.log("pipeline_id", run_id)
  const results = await api.post(`/analysis/delete`, { run_id: run_id })
  // dispatch({ type: UPDATE_PROJECT, payload: results.data })
}

export const analysisAddReport = (run_id, report) => async dispatch => {
  await api.post(`/analysis/addreport`, { run_id: run_id, report: report }).then(() => {
    dispatch({ type: ADD_REPORT, payload: report })
  })
}

export const analysisUpdateReport = (run_id, report, index) => async dispatch => {
  console.log("report", index)
  await api.post(`/analysis/updatereport`, { run_id: run_id, report: report, index:index }).then(() => {
    // dispatch({ type: UPDATE_REPORT, payload: report })
  })
}

// get reports
export const getReports = (run_id) => async dispatch => {
  const results = await api.post(`/analysis/getreports`, { run_id: run_id })
  if (results.status === 200) {
    console.log(results.data)
    dispatch({ type: GET_REPORTS, reports: results.data })
  }
}