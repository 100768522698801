import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import DriveModal from "./driveModal";
import RightGuideSlideout from "../RightGuideSlideout";



const ReferenceFile2 = ({ plf, setReferenceFileOut, workflow }) => {
  const [references, setReferences] = useState([]);
  const [showDriveModal, setShowDriveModal] = useState(false);
  const [handleRefSelect, setHandleRefSelect] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [activeChoosenInput, setActiveChoosenInput] = useState();

  useEffect(() => {
    let _references = [];
    workflow?.steps.reference?.settings.forEach((item) => {
      item.value = item.test_input;
      _references.push(item);
    });
    setReferences(_references);
    console.log(_references);
    setReferenceFileOut(_references);
  }, [workflow]);

  useEffect(() => {
    // Row selection
    console.log(rowSelection);
    try {
      let selectedRow = Object.keys(rowSelection);
      selectedRow = parseInt(selectedRow[0]);

      let x = plf[selectedRow];
      if (!x) return;
      let _references = references.map((item) => {
        if (item.name === activeChoosenInput.name) {
          let file_path;
          if (x.full_file_path.split("/")[0] === "uploads") {
            file_path = `gs://${x.bucket_id}/uploads/${x.imaginary_file_path}`;
          } else {
            file_path = `gs://${x.bucket_id}/${x.imaginary_file_path}`;
          }
          return { ...item, value: file_path };
        }
        return item;
      });
      setReferences(_references);
      setReferenceFileOut(_references);
    } catch (error) {
      console.log(error);
    }
  }, [rowSelection]);

  return (
    <>
      <div
        className="analysis-reference-block"
        style={{
          display: "flex",
        }}
      >
        {/* <div className="text-block-50">{"stepInfo"}</div> */}
        <div className="text-block-50"></div>
        {workflow?.steps.reference?.sections.map((section) => {
          return (
            <>
            <div style={{fontSize: "18px", fontWeight: "bold", marginBottom: "4px"}}>{section}</div>
            <div className="div-block-95-copy">

              {references.map((reference, index) => {
                if (reference.section === section) {

                  let _options = [];
                  if (Array.isArray(reference.options)) {
                    _options = reference.options;
                  } else if (typeof reference.options === 'object') {
                    console.log("line 79", reference, references);
                    let _value = references.find(item => item.name === reference.options.parent).value
                    reference.options.options.forEach(item => {
                      console.log(item);
                      if (item["parent-value"] === _value) {
                        _options = item.options;
                      }
                    });
                    console.log(_options);
                  }
                  return (
                    <div
                      className="div-block-96"
                      style={{
                        display: "flex",
                        // position: "relative",
                      }}
                    >
                      {reference.guide && <RightGuideSlideout guideContent={reference.guide} />}
                      <div
                        className="text-block-52"
                        style={{
                          display: "block",
                        }}
                      >
                        {reference.name.charAt(0).toUpperCase() +
                          reference.name.slice(1)}{" "}
                        - <br />
                        {(() => {
                          try {
                            return <b>{reference?.value.split("/").pop()}</b>;
                          } catch (error) {
                            return <b>{reference?.value}</b>;
                          }
                        })()}
                      </div>
                      {handleRefSelect.length > 0 ? (
                        <div
                          className="reference-dropdown"
                          onClick={() => setShowDriveModal(true)}
                        >
                          {handleRefSelect?.selectedRows[0]?.file_name}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="div-block-98">
                        <div style={{
                          display: reference.interface_type === "dropdown" ? "flex" : "none"
                        }}>
                        <Select
                          value={{
                            value: reference.value ? reference.value : _options[0]?.value,
                            label: reference.value ? reference.value : _options[0]?.label,
                          }}

                          onChange={(selectedOption) => {
                            const updatedReferences = [...references];
                            updatedReferences[index].value =
                              selectedOption.value;
                            console.log(updatedReferences);
                            setReferences(updatedReferences);
                            setReferenceFileOut(updatedReferences);
                          }}
                          options={_options}
                          className={"reference-dropdown"}
                          styles={{
                            width: "100%",
                          }}
                          isDisabled={
                            handleRefSelect.length === 0 ? false : true
                          }
                          menuPosition="fixed"
                          menuPlacement="auto"
                        />
                        </div>
                        <div
                          // className="text-block-53"
                          className="btn-1 type-2"
                          style={{
                            marginLeft: "14px",
                            display: reference.interface_type.includes(
                              "drive_file_selector"
                            )
                              ? "flex"
                              : "none",
                          }}
                          onClick={() => {
                            setShowDriveModal(true);
                            setActiveChoosenInput(reference);
                            setGlobalFilter(reference.extension[0]);
                          }}
                        >
                          <div
                            className="btn-1-text"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Choose From <br />
                            Storage
                          </div>
                        </div>
                        <div
                          // className="text-block-53"
                          className="btn-1 type-2"
                          style={{
                            marginLeft: "14px",
                            display: reference.interface_type.includes(
                              "external_link"
                            )
                              ? "flex"
                              : "none",
                          }}
                          onClick={() => {
                            let _link;
                            let _value = references.find(item => item.name === reference.link.parent).value;
                            if (!_value) {
                              _value = reference.link.options[0]["parent-value"];
                            }
                            reference.link.options.forEach(item => {
                              if (item["parent-value"] === _value) {
                                _link = item.link;
                              }
                            });
                            window.open(_link, "_blank");
                          }}
                        >
                          <div
                            className="btn-1-text"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Open Link
                          </div>
                        </div>
                      </div>
                    </div>

                  );
                  
                }
              })}
            </div>
            </>

          );
        })}
      </div>
      <DriveModal
        show={showDriveModal}
        handleClose={() => setShowDriveModal(false)}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        data={plf}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </>
  );
};

export default ReferenceFile2;
